const options = {
  headers: {
    "Content-Type": "application/json",
    "x-access-token":
      "coinranking0ac57587d0a826ca384bd037816df6758f38788d3197de85", // Include your actual API access token
  },
};

export default function fetchData() {
  return fetch("https://api.coinranking.com/v2/coin/eNIORo_rN", options)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((result) => {
      // Return only the necessary data
      return {
        price: result.data.coin.price,
        marketCap: result.data.coin.marketCap,
        volume24h: result.data.coin["24hVolume"],
      };
    });
}
