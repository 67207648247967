import React, { useState, useEffect } from "react";
import fetchData from "../../components/CoinrankingAPI/api";
import "./styles.css";

function Desktop() {
  const [price, setPrice] = useState("Loading...");
  const [marketCap, setMarketCap] = useState("Loading...");
  const contractAddress = "8pLnwrjsmDnmJwR6ae8R3Gox93578NibjkzXRtKwfN66";

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(contractAddress)
      .then(() => {
        alert("Contract address copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  useEffect(() => {
    const updateData = () => {
      fetchData()
        .then((data) => {
          setPrice(data.price);
          setMarketCap(data.marketCap);
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          setPrice("Error loading price");
          setMarketCap("Error loading market cap");
        });
    };

    updateData(); // Initial fetch
    const intervalId = setInterval(updateData, 300000); // Fetch every 5 minutes

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return (
    <div className="MARV">
      <div className="div">
        <div className="NAV">
          <a href="/">
            <img className="LOGO" alt="Logo" src="/img/NAVLOGO.png" />
          </a>{" "}
          <a href="#marv-story">
            <div className="story-b">
              <div className="group">
                <div className="overlap-group">
                  <div className="text-wrapper">STORY</div>
                </div>
              </div>
            </div>
          </a>
          <a
            href="https://medium.com/@marvinlightyear"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="medium-b">
              <img className="medium" alt="Medium" src="/img/M.png" />
            </div>
          </a>
          <a
            href="https://t.me/+33ClDA2OcVkxZDA0"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="tellagram-b">
              <img
                className="telegram"
                alt="Telegram"
                src="/img/telegram.png"
              />
            </div>
          </a>
          <a
            href="https://twitter.com/MarvinLightYear"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="twitter-b">
              <img className="twitter" alt="Twitter" src="/img/twitter.png" />
            </div>
          </a>
          <a
            href="https://dexscreener.com/solana/cojtqjb7sew3nxfvzanrdxcduwduvuurw8d9ra3ed1cm"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="buy-b">
              <div className="overlap-group-wrapper">
                <div className="div-wrapper">
                  <div className="text-wrapper-2">BUY $MARV</div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="purplebubble">
          <img className="marvtoken" alt="MARV" src="/img/MARVTOKEN.png" />
          <div className="price">PRICE: ${price}</div>
          <div className="liquidity">MARKET CAP: ${marketCap}</div>
          <div className="marvprice">
            <div className="marvliquidity"></div>
          </div>
        </div>
        <div className="landing-text">
          <div className="text-wrapper-3">WELCOME TO MARV</div>
          <p className="derived-from-love">
            Derived from the love child of Marvin the Martian & Buzz LightYear,
            combobulated with popular internet meme culture.
            <br />
          </p>
          <div className="text-wrapper-4"></div>
        </div>
        <div className="ticket-tape">
          <p class="p">
            $MARV - $MARV - $MARV - $MARV - $MARV - $MARV - $MARV - $MARV -
            $MARV - $MARV -
          </p>
          <p class="p">
            $MARV - $MARV - $MARV - $MARV - $MARV - $MARV - $MARV - $MARV -
            $MARV - $MARV -
          </p>
        </div>
        <div className="token">
          <div className="text-wrapper-5">$MARV Tokenomics</div>
          <div className="text-wrapper-6">
            1 Billion $MARVs on Base & Solana
          </div>
          <div className="boxes">
            <div className="overlap">
              <a
                href="
              "
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="rectangle" />
                <img
                  className="img"
                  alt="Rectangle"
                  src="/img/rectangle-2968.png"
                />
                <div className="text-wrapper-7">Community Backed</div>
              </a>
            </div>
            <div className="overlap-group-2">
              <img
                className="rectangle-2"
                alt="Rectangle"
                src="/img/rectangle-2967.png"
              />
              <div className="text-wrapper-7">Renounced</div>
            </div>
            <a
              href="https://www.soltool.app/locker/lp/8oLLCRwwqLjY45hdMDLg4xwSNAxftDTrRub6i28jfMTi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="overlap-2">
                <img
                  className="img"
                  alt="Rectangle"
                  src="/img/rectangle-2969.png"
                />
                <div className="text-wrapper-7">LP Locked</div>
              </div>
            </a>
          </div>
          <p className="CA">
            <span className="span">Contract Address: </span>
            <span className="span">{contractAddress}</span>
          </p>
          <div className="group-wrapper">
            <div className="group-2" onClick={copyToClipboard}>
              <div className="overlap-group-3">
                <div className="text-wrapper-8">COPY</div>
              </div>
            </div>
          </div>
        </div>
        <div id="marv-story" className="marv-story">
          <div className="text-wrapper-9">Marv's Story</div>
          <p className="total-billion">
            <br />
            <br />
            Marv, the all-knowing oracle of the Base Blockchain, has set out on
            an exciting journey to bring innovation to the world of
            decentralized finance. Marv knows everything about DeFi and is about
            to unveil his unique basket protocol that allows you to easily dive
            in and out of the top cryptocurrencies and meme coins with just one
            click.
            <br />
            <br />
            But that’s not all. Marv is also bringing multi-chain baskets,
            making it easier than ever for crypto enthusiasts to join the latest
            and greatest movements across different networks with a single
            click.
            <br />
            <br />
            Whether you’re on Ethereum, Binance Smart Chain, or any other chain,
            Marv makes it simple and fun to be part of the ever-evolving world
            of crypto.
          </p>

          <div className="box">
            <div className="new-basket">
              <div className="box-overlap">
                <div className="box-overlap-group">
                  <div className="box-rectangle" />
                  <div className="box-text-wrapper">
                    Marvs Basket Pick (Base)
                  </div>
                  <p className="div110">
                    A carefully hand picked basket of the top performing meme
                    tokens. Sometimes it's the simple things that matter.
                  </p>
                </div>
                <div className="box-text-wrapper-2">Coming Soon</div>
                <div className="box-overlap-2">
                  <img
                    className="box-element"
                    alt="Element"
                    src="/img/M12345.png"
                  />
                </div>
                <div className="buy-b">
                  <div className="box-div-wrapper">
                    <div className="box-text-wrapper-3">BUY $ Basket</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="b">
            <div className="overlap-group-4">
              <div className="rectangle-3" />
              <div className="text-wrapper-10">No Commitment</div>
              <p className="text-wrapper-11">
                Marv’s basket protocol is designed for convenience, letting you
                explore the crypto world without the hassle.
              </p>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap-group-4">
              <div className="rectangle-3" />
              <div className="text-wrapper-10">Easy Access</div>
              <p className="text-wrapper-11">
                With Marv's protocol, there's no need for complex roadmaps. Just
                click, and you’re in, with easy access to the crypto universe.
              </p>
            </div>
          </div>
          <div className="b-2">
            <div className="overlap-group-4">
              <div className="rectangle-3" />
              <div className="text-wrapper-10">Community First</div>
              <p className="text-wrapper-11">
                Marv’s community is all about sharing knowledge and celebrating
                achievements together. Let’s explore the crypto world hand in
                hand.
              </p>
            </div>
          </div>
          <div className="b-3">
            <div className="overlap-group-4">
              <div className="rectangle-3" />
              <div className="text-wrapper-10">Get Involved</div>
              <p className="text-wrapper-11">
                Dive into the world of Marv and embrace the spirit of crypto.
                Join the movement and be part of this groundbreaking DeFi
                journey!
              </p>
            </div>
          </div>
          <div className="contact">
            <a
              href="https://t.me/+33ClDA2OcVkxZDA0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="telegram-b-2">
                <img
                  className="telegram"
                  alt="Telegram"
                  src="/img/telegram.png"
                />
              </div>
            </a>
            <a
              href="https://medium.com/@marvinlightyear"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="medium-b-2">
                <img className="medium-2" alt="Medium" src="/img/M.png" />
              </div>
            </a>

            <a
              href="https://twitter.com/MarvinLightYear"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="twitter-b-2">
                <div className="rectangle-wrapper">
                  <img
                    className="rectangle-4"
                    alt="Rectangle"
                    src="/img/rectangle-2961.svg"
                  />
                </div>
              </div>
            </a>
            <a
              href="https://dexscreener.com/solana/cojtqjb7sew3nxfvzanrdxcduwduvuurw8d9ra3ed1cm"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="buy-b-2">
                <div className="overlap-3">
                  <div className="text-wrapper-12">BUY $ MARV</div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="find-us">
          <a
            href="https://dexscreener.com/solana/cojtqjb7sew3nxfvzanrdxcduwduvuurw8d9ra3ed1cm"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="dextools">
              <div className="vector-wrapper">
                <img className="vector" alt="Dextools" src="/img/vector.svg" />
              </div>
            </div>
          </a>
          <a
            href="https://coinranking.com/coin/eNIORo_rN+marvinlightyear-marv"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="coinranking">
              <div className="vector-wrapper">
                <img
                  className="vector-2"
                  alt="Coinranking"
                  src="/img/coinranking.png"
                />
              </div>
            </div>
          </a>
          <div className="text-wrapper-13">Find Us & CEX Listings</div>
          <img className="cex-1" alt="cex" src="/img/asc.png" />
          <img className="cex-2" alt="cex" src="/img/bitg.png" />
          <img className="cex-3" alt="cex" src="/img/bitm.png" />
          <img className="cex-4" alt="cex" src="/img/Digifin.png" />
          <img className="cex-5" alt="cex" src="/img/gate.png" />
          <img className="cex-6" alt="cex" src="/img/lbank.png" />
        </div>
        <div className="join-the-adventure">
          <div className="purple-block">
            <div className="overlap-group-5">
              <div className="rectangle-5" />
              <div className="text-wrapper-14">Typical Marv</div>
              <p className="text-wrapper-15">Join in on the adventure</p>
              <p className="of-course-i-find-a">
                Of course, I find a perfectly delightful new polka-free planet,
                only to discover it&#39;s already been overrun by cheery space
                rangers and their offspring with malfunctioning antennae. Just
                my luck.
              </p>
              <img
                className="freepik-export"
                alt="Freepik export"
                src="/img/freepik-export-20240422140809kl5d-1.png"
              />
            </div>
          </div>
          <a
            href="https://unisat.io/runes/detail/MARVIN•LIGHTYEAR"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="nft">
              <div className="NFT-coming-soon-wrapper">
                <p className="NFT-coming-soon">
                  <span className="text-wrapper-16">
                    MINT A MARVIN LIGHTYEAR RUNE
                  </span>
                  <br />
                  <span className="text-wrapper-17">
                    AVAILABLE ON UNISAT, MYSTIC & MORE
                  </span>
                </p>
              </div>
            </div>
          </a>

          <div className="group-3">
            <div className="overlap-4">
              <img
                className="rectangle-6"
                alt="Rectangle"
                src="/img/rectangle-2962.png"
              />
              <div className="frame">
                <p className="text-wrapper-18">
                  $MARV - $MARV - $MARV - $MARV - $MARV - $MARV - $MARV - $MARV
                  - $MARV - $MARV -
                </p>
                <p class="text-wrapper-18">
                  $MARV - $MARV - $MARV - $MARV - $MARV - $MARV - $MARV - $MARV
                  - $MARV - $MARV -
                </p>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <a
            href="https://medium.com/@marvinlightyear"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="medium-b-3">
              <img className="medium-3" alt="Medium" src="/img/M.png" />
            </div>
          </a>
          <a
            href="https://t.me/+33ClDA2OcVkxZDA0"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="telegram-wrapper">
              <img
                className="telegram"
                alt="Telegram"
                src="/img/telegram-1.png"
              />
            </div>
          </a>
          <a
            href="https://twitter.com/MarvinLightYear"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="twitter-wrapper">
              <img className="twitter" alt="Twitter" src="/img/twitter-1.png" />
            </div>
          </a>
          <a
            href="https://dexscreener.com/solana/cojtqjb7sew3nxfvzanrdxcduwduvuurw8d9ra3ed1cm"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="buy-b-3">
              <div className="overlap-group-wrapper">
                <div className="div-wrapper">
                  <div className="text-wrapper-2">BUY $ MARV</div>
                </div>
              </div>
            </div>
          </a>
          <img className="LOGO-2" alt="Logo" src="/img/FOOTERLOGO.png" />
          <a href="#marv-story">
            <div className="story-b-2">
              <div className="group">
                <div className="overlap-group">
                  <div className="text-wrapper">STORY</div>
                </div>
              </div>
            </div>
          </a>
        </footer>
      </div>
    </div>
  );
}

export default Desktop;
