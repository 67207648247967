import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Desktop from "./src/screens/desktop";
import Mobile from "./src/screens/mobile";

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Router>
      <Helmet>
        <title>
          Marvin LightYear - Derived from the love child of Marvin the Martian &
          Buzz LightYear, combobulated with popular internet meme culture.
        </title>
        <meta
          name="description"
          content="Join Marvin LightYear on the Base Blockchain! A DeFi basket protocol allowing easy access to top cryptos and meme coins with a click. Multi-chain baskets enable you to join the latest and greatest crypto movements seamlessly."
        />
        <meta
          name="keywords"
          content="Marvin LightYear, Base Blockchain, DeFi, crypto, meme coins, multi-chain, DeFi basket protocol, crypto baskets"
        />
        <meta name="author" content="Marvin LightYear Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="UTF-8" />
      </Helmet>
      <Routes>
        <Route path="/" element={isMobile ? <Mobile /> : <Desktop />} />
      </Routes>
    </Router>
  );
};

export default App;
